import React, { useState, useEffect } from "react";
import { Select, Button, Row, Col, Avatar, Tooltip, Badge } from "antd";
import { LogoutOutlined, UserOutlined, FileExclamationOutlined, FileUnknownOutlined } from "@ant-design/icons";
import { NotificationsActive, ContactEmergency } from "@mui/icons-material";
import { useNavigate, useLocation } from 'react-router-dom';
import apiManager from "../api/apiManager";

import { useUser } from "../api/context/userContext";

export default function MainHeader (props) {

  const { userData } = useUser()
  const userToken = localStorage.getItem("token");
  const userCentersList = JSON.parse(localStorage.getItem("userCenters"));
  const activeCenter = JSON.parse(localStorage.getItem("activeCenter"));

  const [optionsCenter, setOptionsCenter] = useState([])
  const [userInfo, setUsetInfo] = useState({})
  const [bounceIcon, setBounceIcon] = useState(true)
  const [bounceIconFacturation, setBounceIconFacturation] = useState(true)
  const [tovalidate, setToValidate] = useState(0)
  const [toPendingFacturation, setPendingFacturation] = useState(0)

  let navigate = useNavigate()
  let location = useLocation()

  useEffect(() => {
    setOptionsCenter([])
    userCentersList.map((center) => {
      setOptionsCenter(current => [...current, {
        'label': center.identify,
        'value': center.id,
        'status': center.status
      }])
    })
    apiManager.userInformation()
    .then((res) => {
      let response = res.data.data[0]
      setUsetInfo(response)
    })
    if (location.pathname === '/validar-reservas') {
      setBounceIcon(false)
    } else if (location.pathname === '/facturacion-incompleta') {
      setBounceIconFacturation(false)
    }
    apiManager.toValidate(null, null,  null, JSON.parse(localStorage.getItem("activeCenter")).id)
    .then((res) => {
      let result = res.data.data
      if (result.length > 0) {
        setToValidate(result.length)
      } else {
        setBounceIcon(false)
      }
    })
    apiManager.pendingFacturation(null, null,  null, JSON.parse(localStorage.getItem("activeCenter")).id)
    .then((res) => {
      let result = res.data.data
      if (result.length > 0) {
        setPendingFacturation(result.length)
      } else {
        setBounceIconFacturation(false)
      }
    })
  }, [])

  const handleChange = (selected) => {
    let newActiveCenter = {
      id: selected.value,
      identify: selected.label,
      status: selected.status
    }
    localStorage.setItem("activeCenter", JSON.stringify(newActiveCenter))
    props.refreshPage()
  }

  const logout = () => {
    localStorage.removeItem("token")
    navigate("/")
  };

  const goToRoute = (path) => {
    navigate(path)
  }

  function importAll(r) {
    let images = {}
    r.keys().map((item, index) => { images[item.replace('./', '').split('.').shift()] = r(item); })
    return images
  }

  const images = importAll(require.context('../componentes/Images', false, /\.(png|jpe?g|svg)$/))

  return (
    <>
      <Row justify="space-between" style={{alignItems: "center", height: "100%"}}>
        <Col span={8}></Col>
        <Col span={8} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
          <img
            style={{maxHeight: 40}}
            src={images.logo}
            //onClick={() => goToRoute("/")}
          ></img>
        </Col>
        <Col span={8} style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
          {
            bounceIcon ?
              <div className='validateBtn'>
                <Tooltip placement="bottom" title="Reservas a validar">
                  <Badge count={tovalidate}>
                    <Button
                      className="iconCircle"
                      onClick={() => goToRoute('/validar-reservas')}
                    >
                      <NotificationsActive className="ring" style={{fill: '#FFF', margin: 'auto'}} />
                    </Button>
                  </Badge>
                </Tooltip>
              </div>
            : <></>
          }
          {
            bounceIconFacturation ?
              <div style={{marginLeft: 18}} className='validateBtn'>
                <Tooltip placement="bottom" title="Facturación incompleta">
                  <Badge count={toPendingFacturation}>
                    <Button
                      className="iconCircle"
                      onClick={() => goToRoute('/facturacion-incompleta')}
                    >
                      <ContactEmergency style={{fill: '#FFF', margin: 'auto'}} />
                    </Button>
                  </Badge>
                </Tooltip>
              </div>
            : <></>
          }
          <Select
            defaultValue={activeCenter.id}
            onChange={(e, a) => handleChange(a)}
            options={optionsCenter}
            style={bounceIcon || bounceIconFacturation ? {marginLeft: 25} : {}}
          />
          <Tooltip placement="bottom" title={userData !== null ? userData.email : ''}>
            <Avatar
              className="avatarHeader"
              size={30}
              style={{
                backgroundColor: '#ff4d4f',
                marginLeft: 20
              }}
              icon={<UserOutlined className="white"/>}
            />
          </Tooltip>
          <Tooltip placement="bottom" title={"Logout"}>
            <Button
              className="iconCircle"
              onClick={() => logout()}
              style={{marginLeft: 10}}
            ><LogoutOutlined className="white" /></Button>
          </Tooltip>
        </Col>
      </Row>
    </>
  )
}