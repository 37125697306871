import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, HashRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import { ConfigProvider } from "antd";
import esES from "antd/locale/es_ES"
import "bootstrap/dist/css/bootstrap.css";
import dayjs from "dayjs";
import 'dayjs/locale/es';

//context
import { AuthProvider } from "./api/context/AuthProvider";

dayjs.locale('es');

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <AuthProvider>
      <HashRouter>
        <ConfigProvider locale={esES}>
          <App />
        </ConfigProvider>
      </HashRouter>
    </AuthProvider>
  </React.Fragment>
);
