import React from "react";
import { Helmet } from "react-helmet";

export default function BasicLayout ({children, ...pageProps}) {

  const setBGColor = () => {
    let envValue = process.env.REACT_APP_BASE_BACKGROUND !== undefined ? process.env.REACT_APP_BASE_BACKGROUND : '#d53369,#daae51'
    let colors = envValue.split(',')
    let colorsRgb = []
    for (const color of colors) {
      let colorRgb = hexToRgb(color)
      colorsRgb.push(colorRgb)
    }
    document.documentElement.style.setProperty('--background-global-color1', `rgb(${colorsRgb[0].r}, ${colorsRgb[0].g}, ${colorsRgb[0].b})`)
    document.documentElement.style.setProperty('--background-global-color2', `rgb(${colorsRgb[1].r}, ${colorsRgb[1].g}, ${colorsRgb[1].b})`)
  }

  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  setBGColor()

  function importAll(r) {
    let images = {}
    r.keys().map((item, index) => { images[item.replace('./', '').split('.').shift()] = r(item); })
    return images
  }

  const images = importAll(require.context('../componentes/Images', false, /\.(png|jpe?g|svg)$/))

  return (
    <div
      style={{
        display: 'grid',
        placeContent: 'center',
        height: '100vh',
      }}
      className="basic-layout"
    >
      <Helmet>
        <title>{ pageProps.title !== undefined ? pageProps.title : "RocRoi" }</title>
        <meta name="description" content="App Description" />
        <link rel="icon" type="image/png" href={images.favicon} sizes="32x32" />
        {/* <script defer src="https://app.embed.im/snow.js" /> */}
      </Helmet>
      { children }
    </div>
  )
}