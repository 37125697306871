/* eslint-disable */

import React from "react";
import MainLayout from "../../layouts/MainLayout";
import ClosedCashComp from "../../componentes/Informes/ClosedCashComp";

export default function ClosedCash() {
  return (
    <ClosedCashComp />
  )
}
