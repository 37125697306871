import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import apiManager from '../../api/apiManager'
import BadPermissions from '../Error/BadPermissions'

export default function PrivateRoute(props) {

  const element = props.children
  const isLoggedIn = localStorage.getItem("token") !== null

  const [userInfo, setUserInfo] = useState({})
  const [ready, setReady] = useState(false)

  useEffect(() => {
    async function getUserInfo() {
      apiManager.userInformation(localStorage.getItem("token"))
      .then((res) => {
        let result = res.data.data[0]
        setUserInfo(result)
        setReady(true)
      })
    }
    getUserInfo()
  }, [])
  
  return isLoggedIn ?
    userInfo.is_b2b !== props.b2b ?
      ready ?
        <BadPermissions />
      : <></>
    : element
  : <Navigate to="/" />

}
