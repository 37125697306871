/* eslint-disable */

import React from "react";
import MainLayout from "../../layouts/MainLayout";
import TableActivities from "../../componentes/Actividades/TableActivities";

export default function Actividades() {
  return (
    <TableActivities />
  )
}
