/* eslint-disable */

import React from "react";
import MainLayout from "../../layouts/MainLayout";
import TableValidateBookings from "../../componentes/Tabla/TableValidateBookings";

export default function ValidateBookings() {
  return (
    <TableValidateBookings />
  )
}
