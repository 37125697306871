import React, { useState, useEffect } from "react";
import { Select, Button, Row, Col, Avatar, Tooltip, Badge, Switch } from "antd";
import { LogoutOutlined, UserOutlined, FileExclamationOutlined, FileUnknownOutlined } from "@ant-design/icons";
import { LightMode, DarkMode } from "@mui/icons-material";

import { useUser } from "../api/context/userContext";

export default function B2BMainHeader (props) {

  const { userData, logoutUser } = useUser()
  const userCentersList = JSON.parse(localStorage.getItem("userCenters"));
  const activeCenter = JSON.parse(localStorage.getItem("activeCenter"));

  const [optionsCenter, setOptionsCenter] = useState([])

  useEffect(() => {
    setOptionsCenter([])
    userCentersList.map((center) => {
      setOptionsCenter(current => [...current, {
        'label': center.identify,
        'value': center.id,
        'status': center.status
      }])
    })
  }, [])

  const handleChange = (selected) => {
    let newActiveCenter = {
      id: selected.value,
      identify: selected.label,
      status: selected.status
    }
    localStorage.setItem("activeCenter", JSON.stringify(newActiveCenter))
    props.refreshPage()
  }

  function importAll(r) {
    let images = {}
    r.keys().map((item, index) => { images[item.replace('./', '').split('.').shift()] = r(item); })
    return images
  }

  const images = importAll(require.context('../componentes/Images', false, /\.(png|jpe?g|svg)$/))

  const updateViewMode = (value) => {
    props.updateView(value)
  }

  return (
    <>
      <Row justify="space-between" style={{alignItems: "center", height: "100%"}}>
        <Col span={8}></Col>
        <Col span={8} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
          <img
            style={{maxHeight: 40}}
            src={props.viewMode ? images.logo : images.logoWhite}
            //onClick={() => goToRoute("/")}
          ></img>
        </Col>
        <Col span={8} style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
          <Select
            defaultValue={activeCenter.id}
            onChange={(e, a) => handleChange(a)}
            options={optionsCenter}
          />
          <Tooltip placement="bottom" title={userData === null ? '' :
            <div style={{display: 'grid', placeContent: 'center', gap: 10}}>
              <p style={{color: '#FFF', textAlign: 'center'}}>{userData.name}</p>
              <Switch
                className="swith-mode-btn"
                checkedChildren={<LightMode style={{fill: "#FFF"}} />}
                unCheckedChildren={<DarkMode style={{fill: "#FFF"}} />}
                defaultChecked={props.viewMode}
                onChange={(e) => updateViewMode(e)}
              />
            </div>
          }>
            {
              userData !== null && userData.is_b2b ?
              userData.avatar !== '' ?
                  <Avatar
                    src={<img src={userData.avatar} alt="avatar" />}
                    size={30}
                    style={{marginLeft: 20}}
                  />
                :
                  <Avatar
                    className="avatarHeader"
                    size={30}
                    style={{
                      backgroundColor: '#ff4d4f',
                      marginLeft: 20
                    }}
                    icon={<UserOutlined className="white"/>}
                  />
              : <Avatar
                  className="avatarHeader"
                  size={30}
                  style={{
                    backgroundColor: '#ff4d4f',
                    marginLeft: 20
                  }}
                  icon={<UserOutlined className="white"/>}
                />
            }
          </Tooltip>
          <Tooltip placement="bottom" title={"Logout"}>
            <Button
              className="iconCircle"
              onClick={() => logoutUser()}
              style={{marginLeft: 10}}
            ><LogoutOutlined className="white" /></Button>
          </Tooltip>
        </Col>
      </Row>
    </>
  )
}