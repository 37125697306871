import React, {useState, useEffect, useRef} from "react";
import { DatePicker, Button, Row, Col, Select, Tooltip } from "antd";
import { Refresh, Download } from "@mui/icons-material";
import TableTreasuryBook from "../../componentes/Tabla/TableTreasuryBook";
import apiManager from "../../api/apiManager";

export default function TreasuryBook() {

  useEffect(() => {
    initFuncion()
  }, [])

  const childRef = useRef()
  const dateFormat = "DD-MM-YYYY"
  const { RangePicker } = DatePicker

  const [dates, setDates] = useState([])
  const [datesSelected, setDatesSelected] = useState([])
  const [paymentMethodsList, setPaymentMethodsList] = useState([])
  const [paymentMethod, setPaymentMethod] = useState("")
  const [paymentMethodSelected, setPaymentMethodSelected] = useState(null)
  const [compKey, setCompKey] = useState(0)
  const [download, setDownload] = useState(0)
  const [dataLength, setDataLength] = useState(0)

  const initFuncion = () => {
    setPaymentMethodsList([])
    apiManager.getPaymentMethods(null, JSON.parse(localStorage.getItem("activeCenter")).id)
    .then((res) => {
      let result = res.data.data
      result.map((method) => {
        setPaymentMethodsList(current => [...current, {
          'value': method.title,
          'label': method.title,
          'id': method.id
        }])
      })
    })
  }

  const findForDate = (values, dates) => {
    setDatesSelected(dates)
    setDates(values)
    setDownload(0)
    setCompKey(compKey + 1)
  }

  const changePaymentMethod = (value) => {
    setPaymentMethodSelected(value.value)
    setPaymentMethod(value.id)
    setDownload(0)
    setCompKey(compKey + 1)
  }

  const refreshValues = () => {
    setPaymentMethodSelected(null)
    setDatesSelected([])
    setPaymentMethod("")
    setDates([])
    setDownload(0)
    setCompKey(compKey + 1)
  }

  const dataOfTable = (data) => {
    setDataLength(data.length)
  }

  return (
    <>
      <Row
        justify={'space-between'}
        style={{marginBottom: 20}}
      >
        <Col
          span={8}
          className="menu-col"
          style={{display: "flex", justifyContent: "left", alignItems: "center", gap: 8}}
        >
          <Select
            style={{ width: 220 }}
            placeholder="Selecciona un método de pago"
            onChange={(e, a) => changePaymentMethod(a)}
            value={paymentMethodSelected}
            options={paymentMethodsList}
          />
          <RangePicker
            format={dateFormat}
            onChange={(e, a) => findForDate(a, e) }
            placeholder={["Desde", "Hasta"]}
            value={datesSelected}
            disabled={paymentMethod === ""}
          />
          <Button
            disabled={paymentMethod === "" && dates.length === 0}
            onClick={() => refreshValues()}
          ><Refresh /></Button>
          <Tooltip title="Descargar CSV">
            <Button
              onClick={() => setDownload((download) => download + 1)}
              disabled={dataLength === 0}
            >
              <Download />
            </Button>
          </Tooltip>
        </Col>
        <Col span={8}></Col>
        <Col span={8}></Col>
      </Row>
      <TableTreasuryBook
        ref={childRef}
        key={compKey}
        paymentMethod={paymentMethod}
        dates={dates}
        download={download}
        dataOfTable={dataOfTable}
      />
    </>
  )
}