import React, { useState} from "react";

//Components
import Header from "../../componentes/Header/header";
import Calendario from "../../componentes/Calendario/Calendario";
import styles from "./reservas.module.css";
import { useEffect } from "react";
import MainLayout from "../../layouts/MainLayout";
import TableBooking from "../../componentes/Tabla/TableBooking";


//context

export default function Reservas() {
  useEffect(() => {
    checkUserToken();
  }, []);

  const checkUserToken = () => {
    if (userToken === null) {
      setUserLoggedIn(false);
    } else {
      setUserLoggedIn(true);
    }
  };

  const [userLoggedIn, setUserLoggedIn] = useState(false);

  const userToken = localStorage.getItem("token");

  return (
    <React.Fragment>
      <TableBooking />
    </React.Fragment>
  );
}
