/* eslint-disable */

import React, { useState } from "react";

//UI

import Button from "../../componentes/UI/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";

//Components
import Header from "../../componentes/Header/header";
import styles from "./../Reservas/reservas.module.css";
import styless from "./buscadorReservas.module.css";
import Modal from "./../../componentes/UI/Modal";
import TablaBuscadorReservas from "../../componentes/Tabla/TablaBuscadorReservas";
import SearchBookingTable from "../../componentes/Tabla/SearchBookingTable";
import MainLayout from "../../layouts/MainLayout";

//import api Manager
import axios from "axios";
import { useEffect } from "react";

export default function BuscadorReservas() {
  useEffect(() => {
    getCenterCanals();
    getReservas();
  }, []);

  const [modalShow, setModalShow] = useState(false);

  //reservasFiltradas por creacion
  const [reservasConFiltroAplicado, setReservasConFiltroAplicado] = useState(
    []
  );

  //today
  const [todayString, setTodayStr] = useState("");
  localStorage.setItem("today", JSON.stringify(todayString));
  let today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  today = dd + "-" + mm + "-" + yyyy;

  const centerId = JSON.parse(localStorage.getItem("activeCenter"));

  //get reservas from today
  const [reservasDelDia, setReservasDelDia] = useState([]);
  const getReservas = () => {
    axios
      .get(
        process.env.REACT_APP_FHIOS_API_URL + "simple_booking/between_dates?oneDayStartDate=11-10-2022&centerId=" +
          centerId.id
      )
      .then((res) => {
        setReservasDelDia(res.data.data);

        // setSearchApiData(res.data.data);
      });
  };

  //*****************Filter by fecha/fechas reservas
  const [reservaDateValuee, setReservaDateValue] = useState("");
  const [reservaDateValuee2, setReservaDateValue2] = useState("");

  const filterByCreacionReserva = (
    reservaDateValue = reservaDateValuee,
    reservaDateValue2 = reservaDateValuee2
  ) => {
    axios
      .get(
        process.env.REACT_APP_FHIOS_API_URL + `bookingsearch_list?createStartDate=${reservaDateValue}&CreateEndDate=${reservaDateValue2}&centerId=${centerId.id}`
      )
      .then((res) =>
        setReservasConFiltroAplicado(res.data.data, "reserva seleccionada")
      );
  };

  //*****************Filter by entrada/entradas de reservas

  const [entradaDateValuee, setEntradaDateValue] = useState("");
  const [entradaDateValuee2, setEntradaDateValue2] = useState("");

  const filterByEntrada = (
    entradaDateValue = entradaDateValuee,
    entradaDateValue2 = entradaDateValuee2
  ) => {
    axios
      .get(
        process.env.REACT_APP_FHIOS_API_URL + `bookingsearch_list?startDate=${entradaDateValue}&endDate=${entradaDateValue2}&centerId=${centerId.id}`
      )
      .then((res) =>
        setReservasConFiltroAplicado(res.data.data, "reserva seleccionada")
      );
  };

  //***************** CentersByCanal

  const activeCenter = JSON.parse(localStorage.getItem("activeCenter"));
  const activeCenterId = activeCenter.id;

  //canal value selected
  const [centerCanals, setCenterCanals] = useState([]);

  const getCenterCanals = () => {
    axios
      .get(
        process.env.REACT_APP_FHIOS_API_URL + "sales_channel_list?centerId=" +
          activeCenterId
      )
      .then((res) => setCenterCanals(res.data.data, "canalllll"));
  };

  //filter by canal
  const filterByCanal = (canalVal) => {
    const matchByCanal = reservasDelDia.map((reserva) => {
      if (canalVal === reserva.channel && reserva) {
        return reserva;
      }
    });

    const matchFiltered = matchByCanal.filter((element) => {
      return element !== undefined;
    });
    setReservasConFiltroAplicado(matchFiltered);
  };

  return (
    <SearchBookingTable />
  )
}
