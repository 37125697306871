import React from "react";
import { useEffect, useState } from "react";
import { Table } from "antd";

import apiManager from "../../api/apiManager";

export default function TableMensualProduction(props) {

  const [channelTypeList, setChannelTypeList] = useState([])
  const [channelList, setChannelList] = useState([])

  useEffect(() => {
    setChannelTypeList([])
    setChannelList([])
    apiManager.salesChannelTypeList()
    .then((res) => {
      let result = res.data.data
      result.map((channel) => {
        setChannelTypeList(current => [...current, {
          'text': channel.title,
          'value': channel.title
        }])
      })
    })
    apiManager.getChannels(JSON.parse(localStorage.getItem("activeCenter")).id)
    .then((res) => {
      let result = res.data.data
      result.map((channel) => {
        setChannelList(current => [...current, {
          'text': channel.title,
          'value': channel.title
        }])
      })
    })
  }, [])

  const columns = [
    {
      title: 'Tipo de canal',
      dataIndex: 'salesChannelType',
      filters: channelTypeList,
      onFilter: (value, record) => record.salesChannelType.indexOf(value) === 0,
    },
    {
      title: 'Canal de venta',
      dataIndex: 'salesChannel',
      filters: channelList,
      onFilter: (value, record) => record.salesChannel.indexOf(value) === 0,
    },
    {
      title: 'PAX',
      dataIndex: 'pax',
      align: 'right',
    },
    {
      title: 'Importe',
      dataIndex: 'price',
      align: 'right',
      render: (text) => <span className={Number(text) < 0 ? "red" : ""}>{ Number(text).toFixed(2).replace(/[.,]00$/, "") + "€" }</span>
    }
  ]

  return (
    <>
      <Table
        className="channelProdTable"
        bordered
        columns={columns}
        dataSource={props.data}
        pagination={false}
      />
    </>
  )
}