/* eslint-disable */

import React from "react";
import MainLayout from "../../layouts/MainLayout";
import TableInvoiceIncomplete from "../../componentes/Tabla/TableInvoiceIncomplete";

export default function InvoiceIncomplete() {
  return (
    <TableInvoiceIncomplete />
  )
}
