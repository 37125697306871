import React, { useState } from "react";
import { Routes, Route, useNavigate, useLocation, Router } from "react-router-dom";
import Auth from "../src/componentes/Auth/auth.js";
import PrivateRoute from "./componentes/PrivateRoute/PrivateRoute.js";
import NewAuth from "./componentes/Auth/NewAuth.js";
import apiManager from "./api/apiManager.js";
import WebRoutes from "./pages/WebRoutes/index.js";

import { UserProvider, useUser } from "./api/context/userContext.js";

import "./App.css";
import { useEffect } from "react";

function App() {
  const [userLoggedIn, setUserLoggedIn] = useState(false)
  //const userToken = localStorage.getItem("token")
  const [isB2B, setIsB2B] = useState(false)

  const { getUserData, userData } = useUser()

  const navigate = useNavigate();

  let isBillingUser = false

  const permissonsRequired = [
    "/produc-mensual", "/produc-canal", "/informe-actividades"
  ]

  //today
  function convert(str) {
    let date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day, month, date.getFullYear()].join("-");
  }

  //today
  let today = new Date()
  let location = useLocation()
  const dd = String(today.getDate()).padStart(2, "0")
  const mm = String(today.getMonth() + 1).padStart(2, "0")
  const yyyy = today.getFullYear()
  today = dd + "-" + mm + "-" + yyyy

  convert(today)

  /* const refreshScreen = () => {
    setUserLoggedIn(true)
    if (localStorage.getItem("isb2b") === "false") {
      navigate("/reservas")
    } else {
      navigate("/b2b/reservas")
    }
  } */

  useEffect(() => {
    if (localStorage.getItem("token") === null) {
      setUserLoggedIn(false)
    } else {
      setUserLoggedIn(true)
      getUserData()
      redirectCheck()
    }
    localStorage.setItem("today", JSON.stringify(today))
    let darkMode = localStorage.getItem("viewMode") !== null ?
        localStorage.getItem("viewMode") === "true" ?
          true
        : false
      : true
    if (darkMode) {
      document.body.classList.remove('dark-mode')
    } else {
      document.body.classList.add('dark-mode')
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      apiManager.isBillingAdmin(localStorage.getItem("token"))
      .then((res) => {
        let result = res.data.data[0]
        isBillingUser = result.user_is_billing_admin
        checkPermissons(result.user_is_billing_admin)
      })
    }
  }, [location])

  /* useEffect(() => {
    console.log('navigate')
    if (localStorage.getItem("token") === null) {
      setUserLoggedIn(false)
    } else {
      setUserLoggedIn(true)
    }
  }, [navigate]) */

  /* const checkB2B = () => {
    console.log('check!')
    apiManager.isB2B()
    .then((res) => {
      let result = res.data.data[0]
      console.log(result.user_is_b2_b)
      if (result.user_is_b2_b) {
        navigate("/b2b/dashboard")
      } else {
        navigate("/reservas")
      }
    })
  } */

  const redirectCheck = () => {
    let urlArray = location.pathname.split("/").filter(n => n)
    if (location.pathname === "/" || (urlArray.length === 1 && urlArray[0] === "b2b")) {
      if (userData !== null) {
        if (!userData.is_b2b) {
          navigate("/reservas")
        } else {
          navigate("/b2b/reservas")
        }
      }
    } else if (location.pathname === "/informes") {
      navigate("/produc-mensual")
    }
  }

  const checkPermissons = (billingUser) => {
    if (!billingUser && permissonsRequired.includes(location.pathname)) {
      if (localStorage.getItem("isb2b") === "false") {
        navigate("/reservas")
      } else {
        navigate("/b2b/reservas")
      }
    } else {
      redirectCheck()
    }
  }

  return (
    <React.Fragment>
      <WebRoutes />
    </React.Fragment>
  )
}

export default () => <UserProvider><App /></UserProvider>;
