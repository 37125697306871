import React, { useState, useEffect } from "react";
import { DatePicker, Row, Col, Tabs, Empty } from "antd";
import dayjs from "dayjs";
import apiManager from "../../api/apiManager";
import TableActivitiesReports from "../Tabla/TableActivitiesReports";
import Loader from "../Loader/Loader";

//confirm dialog
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

export default function ActivitiesReportsComp(props) {

  const { RangePicker } = DatePicker;
  const [dataTable, setDataTable] = useState([])
  const [tableKey, setTableKey] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState(false)

  const changeDate = (value) => {
    setIsLoading(true)
    if (value !== null) {
      let startDate = dayjs(value[0]).format("DD-MM-YYYY")
      let endDate = dayjs(value[1]).format("DD-MM-YYYY")
      apiManager.bookingActivitiesByCenter(JSON.parse(localStorage.getItem("activeCenter")).id, startDate, endDate)
      .then((res) => {
        let result = res.data.data
        setDataTable(result)
        setTableKey(tableKey + 1)
        setSearch(true)
        setIsLoading(false)
      })
    } else {
      setDataTable([])
      setSearch(false)
      setIsLoading(false)
    }
  }

  return (
    <>
      <Loader controller={isLoading} />
      <Row>
        <Col span={24} style={{height: 70}}>
          <RangePicker format="DD/MM/YYYY" onChange={(e) => changeDate(e)} />
        </Col>
      </Row>
      <Row>
        { dataTable.length > 0 ?
          <Tabs
            defaultActiveKey="1"
            centered
            //onChange={(e) => changeTab(e)}
            style={{width: "100%"}}
            items={[
              {
                label: "Tabla",
                key: "1",
                children:
                  <Col span={24}>
                    <TableActivitiesReports
                      key={tableKey}
                      data={dataTable}
                    />
                  </Col>
              },
              /* {
                label: "Graficas",
                key: "2",
                children:
                  <Col span={24}>
                    <MensualProductionGraph
                      key={tableKey}
                      data={dataTable}
                    />
                  </Col>
              } */
            ]}
          />
        : <Empty
            className="emptyBoxTable"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{
            width: "100%",
            background: "#FFF",
            display: "grid",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            padding: "100px 0",
            }}
            description={
            search ?
                <span>No hay datos para esta búsqueda</span>
            : <span>Seleccione un rango de fechas</span>
            }
          />
        }
      </Row>
    </>
  )
}