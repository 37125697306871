/* eslint-disable */

import React, { useState, useRef, useEffect, useContext } from "react";
import styles from "./auth.module.css";
import Logo from "../Images/logo-RocRoi.png";
import axios from "axios";
import AuthContext from "../../api/context/AuthProvider";
import { Navigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Favicon from "../Images/favicon.jpg";

export default function(props) {
  const { setAuth } = useContext(AuthContext);
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  const [showForm, setShowForm] = useState(false);

  //set focus wen aplication runs in the user Input
  // useEffect(() => {
  //   userRef.current.focus();
  // }, []);

  //clear error when user or pwd changes
  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  let [authMode, setAuthMode] = useState("signin");
  const changeAuthMode = ({ children }) => {
    setAuthMode(authMode === "signin" ? "forgotpsw" : "signin");
  };

  const handleSubmitLogin = async (e) => {
    e.preventDefault();
    // email:"izan.conejero@fhios.es", pwd: "12345678"

    const response = await axios
      .post(
        process.env.REACT_APP_FHIOS_API_URL + "user/login",
        JSON.stringify({ email: user, pwd })
      )
      .then((response) => {
        if (response.data.message_error === "__user.not.exists") {
          setErrMsg("Login Failed");
          errRef.current.focus();
          return;
        } else {
          const accessToken = response.data.data[0].user_token;
          localStorage.setItem("token", accessToken);
          const activeCenter = response.data.data[0].active_center;
          const userCenters = response.data.data[0].centers;
          localStorage.setItem("userCenters", JSON.stringify(userCenters));
          localStorage.setItem("activeCenter", JSON.stringify(activeCenter));
          setAuth({ email: user, pwd, accessToken });
          props.refreshScreen()
          /* setTimeout(() => {
            props.refreshScreen()
            //setSuccess(true);
          }, 500); */
        }
      })
      .catch((err) => {
        setErrMsg("Login Failed");
      });
  };

  const handleSubmitResetPwd = async (e) => {
    e.preventDefault();
    const data = {
      email: user,
      pwd_code: pwd,
      new_password: newPwd,
    };

    const response = await axios
      .post(
        process.env.REACT_APP_FHIOS_API_URL + "user/reset_pwd",
        JSON.stringify(data)
      )
      .then((response) => {
        if (response.data.message_error == "user.pwdcode.not.match") {
          setErrMsg("User/password do not match");
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSubmitRememberPwd = async (e) => {
    e.preventDefault();
    const data = {
      email: user,
    };

    const response = await axios
      .post(
        process.env.REACT_APP_FHIOS_API_URL + "user/remember_pwd",
        JSON.stringify(data)
      )
      .then((response) => {
        setShowForm(true);
      })
      .catch((err) => console.log(err));
  };

  if (authMode === "signin") {
    return (
      <>
        <Helmet>
          <title>Login</title>
          <meta name="description" content="App Description" />
          <link rel="icon" type="image/png" href={Favicon} sizes="32x32" />
        </Helmet>
        <div className={styles["Auth-form-container"]}>
          <form onSubmit={handleSubmitLogin} className={styles["Auth-form"]}>
            <div className={styles["Auth-form-content"]}>
              <img width="100%" src={Logo}></img>
              <div>
                <p
                  ref={errRef}
                  className={errMsg ? "errmsg" : "offscreen"}
                  aria-live="assertive"
                >
                  {errMsg}
                </p>
              </div>

              <div className="form-group mt-3">
                {success ? (
                  <p>
                    <Navigate replace to="/reservas" />
                  </p>
                ) : (
                  ""
                )}

                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  type="email"
                  className="form-control mt-1"
                  placeholder="Email"
                  ref={userRef}
                  autoComplete="off"
                  onChange={(e) => setUser(e.target.value)}
                  value={user}
                  required
                />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="password">Contraseña</label>
                <input
                  id="password"
                  type="password"
                  onChange={(e) => setPwd(e.target.value)}
                  className="form-control mt-1"
                  placeholder="Enter password"
                  required
                  value={pwd}
                />
              </div>

              <div className="d-grid gap-2 mt-3">
                <button
                  type="submit"
                  className={`btn btn-primary ${styles.authButton}`}
                >
                  Submit
                </button>
              </div>

              <div className="text-center">
                <span
                  className={`link-primary ${styles.authSpan}`}
                  onClick={changeAuthMode}
                >
                  No recuerdas la contraseña?
                </span>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }

  return (
    <div className={styles["Auth-form-container"]}>
      {showForm ? (
        <form onSubmit={handleSubmitResetPwd} className={styles["Auth-form"]}>
          <div className={styles["Auth-form-content"]}>
            <img width="100%" src={Logo}></img>
            <div>
              <p
                ref={errRef}
                className={errMsg ? "errmsg" : "offscreen"}
                aria-live="assertive"
              >
                {errMsg}
              </p>
            </div>
            <div className="form-group mt-3">
              <label className={styles.label}>Correo electrónico</label>
              <input
                type="email"
                className="form-control mt-1"
                placeholder="Correo electrónico"
                onChange={(e) => setUser(e.target.value)}
              />
            </div>
            <div className="form-group mt-3">
              <label className={styles.label}>Código</label>
              <input
                type="password"
                className="form-control mt-1"
                placeholder="Introduzca el código recibido"
                onChange={(e) => setPwd(e.target.value)}
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="newPassword">Nueva contraseña</label>
              <input
                id="newPassword"
                type="password"
                onChange={(e) => setNewPwd(e.target.value)}
                className="form-control mt-1"
                placeholder="Nueva contraseña"
                required
                value={newPwd}
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <button
                onClick={() => {
                  setShowForm(true);
                }}
                type="submit"
                className={`btn btn-primary ${styles.authButton}`}
              >
                Submit email
              </button>
            </div>
            <p>Volver a</p>
            <span
              className={`link-primary ${styles.authSpan}`}
              onClick={changeAuthMode}
            >
              Log In
            </span>
          </div>
        </form>
      ) : (
        <form
          onSubmit={handleSubmitRememberPwd}
          className={styles["Auth-form"]}
        >
          <div className={styles["Auth-form-content"]}>
            <img width="100%" src={Logo}></img>
            <div>
              <p
                ref={errRef}
                className={errMsg ? "errmsg" : "offscreen"}
                aria-live="assertive"
              >
                {errMsg}
              </p>
            </div>
            <div className="form-group mt-3">
              <p>
                Si el email existe vas a recibir un código para cambiar la
                contraseña
              </p>
              <label className={styles.label}>Correo electrónico</label>
              <input
                type="email"
                className="form-control mt-1"
                placeholder="Email Address"
                onChange={(e) => setUser(e.target.value)}
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <button
                // onClick={() => {
                //   setShowForm(true);
                // }}
                type="submit"
                className={`btn btn-primary ${styles.authButton}`}
              >
                Submit email
              </button>
            </div>
            <p>Volver a</p>
            <span
              className={`link-primary ${styles.authSpan}`}
              onClick={changeAuthMode}
            >
              Log In
            </span>
          </div>
        </form>
      )}
    </div>
  );
}
