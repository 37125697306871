import React from "react";
import { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { Column, Line } from '@ant-design/plots';

export default function MensualProductionGraph(props) {

  const [totalImport, setTotalImport] = useState(0)
  const [totalPax, setTotalPax] = useState(0)

  const GraphColumnsPax = (props) => {

    const [data, setData] = useState([]);
    //console.log(props)
    let divider = 4 / props.data.data.months.length

    const modifyTotalPax = (values) => {
      console.log(values)
    }
  
    useEffect(() => {
      let dataConfig = []
      let sumValues = 0
      setTotalPax(0)
      props.data.data.months.map((item) => {
        props.data.data.salesChannelTypeInfo.map((element) => {
          let actual = Object.entries(element).find(key => key[0] === item.dataIndexPax)
          dataConfig.push({
            month: item.title,
            channel: element.salesChannelTypeInfo,
            value: Number(actual[1])
          })
          sumValues = sumValues + Number(actual[1])
        })
      })
      setTotalPax(sumValues)
      setData(dataConfig)
    }, []);
  
    const configPax = {
      data,
      xField: 'month',
      yField: 'value',
      seriesField: 'channel',
      isGroup: true,
      slider: props.data.data.months.length > 4 ? {
        start: 0,
        end: divider === 1 ? 0 : Number(Math.floor(divider + 'e1') + 'e-1'),
      } : null,
      legend: {
        position: 'bottom',
        flipPage: false
      },
      /* label: {
        position: 'middle',
        layout: [
          {
            type: 'interval-adjust-position',
          },
          {
            type: 'interval-hide-overlap',
          },
          {
            type: 'adjust-color',
          },
        ],
      }, */
      //color: ['#ff4d4f', '#df437e', '#a84f97', '#6b5695', '#3b537c', '#2f4858'],
    }
  
    return (
      <Column
          {...configPax}
          onReady={(plot) => {
            plot.on('legend-item:click', (evt) => {
              console.log(plot.options)
              const { x, y } = evt;
              const tooltipData = plot.chart.getTooltipItems({ x, y });
              modifyTotalPax(tooltipData);
            });
          }}
        />
    )
  }

  const GraphColumnsImport = (props) => {

    const [data, setData] = useState([]);
    let divider = 4 / props.data.data.months.length
  
    useEffect(() => {
      let dataConfig = []
      let sumValues = 0
      setTotalImport(0)
      props.data.data.months.map((item) => {
        props.data.data.salesChannelTypeInfo.map((element) => {
          let actual = Object.entries(element).find(key => key[0] === item.dataIndexPrice)
          dataConfig.push({
            month: item.title,
            channel: element.salesChannelTypeInfo,
            value: Number(actual[1])
          })
          //console.log(actual[1])
          sumValues = sumValues + Number(actual[1])
        })
      })
      setTotalImport(sumValues)
      setData(dataConfig)
    }, []);
  
    const modifyTotalImport = (values) => {
      console.log(values)
    }
  
    const configImport = {
      data,
      xField: 'month',
      yField: 'value',
      seriesField: 'channel',
      isGroup: true,
      slider: props.data.data.months.length > 4 ? {
        start: 0,
        end: divider === 1 ? 0 : Number(Math.floor(divider + 'e1') + 'e-1'),
      } : null,
      legend: {
        position: 'bottom',
        flipPage: false
      },
      //color: ['#ff4d4f', '#df437e', '#a84f97', '#6b5695', '#3b537c', '#2f4858'],
      //color: ['#ff4d4f','#ff723b', '#ff9724', '#ffbc09', '#fae014', '#F0E275'],
    }
  
    return (
      <Column
        {...configImport}
        onReady={(plot) => {
          plot.on('legend-item:click', (evt) => {
            console.log(plot.options)
            const { x, y } = evt;
            const tooltipData = plot.chart.getTooltipItems({ x, y });
            modifyTotalImport(tooltipData);
          });
        }}
      />
    )
  
  }

  return (
    <>
      <Row justify="space-between">
        <Col span={11}>
          <h2 style={{marginBottom: 30}}>Pax <span>(<b>TOTAL</b>: {Math.round(totalPax * 10) / 10})</span></h2>
          <GraphColumnsPax data={props} />
        </Col>
        <Col span={11}>
          <h2 style={{marginBottom: 30}}>Importes <span>(<b>TOTAL</b>: {Math.round(totalImport * 10) / 10}€)</span></h2>
          <GraphColumnsImport data={props} />
        </Col>
      </Row>
      
    </>
  )
}