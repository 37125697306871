/* eslint-disable */

import React from "react";
import MainLayout from "../../layouts/MainLayout";
import ActivitiesReportsComp from "../../componentes/Informes/ActivitiesReportsComp";

export default function ActivitiesReports() {
  return (
    <ActivitiesReportsComp />
  )
}
