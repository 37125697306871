import React, { useEffect, useState, useRef } from "react"
import { Layout } from "antd"
import MainMenu from "./MainMenu";
import MainHeader from "./MainHeader";
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Loader from "../componentes/Loader/Loader";

export default function MainLayout ({children, ...pageProps}) {

  const { Header, Footer, Content } = Layout;
  const [collapsedMenu, setCollapsedMenu] = useState(localStorage.getItem('menuCollapsed') === 'false' ? false : true)
  const [isLoading, setIsLoading] = useState(false)
  const [contentKey, setContentKey] = useState(0)
  let navigate = useNavigate()
  let location = useLocation()

  useEffect(() => {
    setIsLoading(true)
    if (localStorage.getItem("token") !== null) {
      setIsLoading(false)
    } else {
      setIsLoading(false)
      navigate("/")
    }
  }, [])

  const changeCollapsed = (value) => {
    localStorage.setItem('menuCollapsed', value)
    setCollapsedMenu(value)
  }

  const refreshPage = () => {
    //window.location.reload(false)
    setContentKey(contentKey + 1)
  }

  function importAll(r) {
    let images = {}
    r.keys().map((item, index) => { images[item.replace('./', '').split('.').shift()] = r(item); })
    return images
  }

  const images = importAll(require.context('../componentes/Images', false, /\.(png|jpe?g|svg)$/))

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Loader controller={isLoading} />
      <Helmet>
        <title>{ pageProps.title !== undefined ? pageProps.title : "RocRoi" }</title>
        <meta name="description" content="App Description" />
        <link rel="icon" type="image/png" href={images.favicon} sizes="32x32" />
        {/* <script defer src="https://app.embed.im/snow.js" /> */}
      </Helmet>
      <MainMenu
        changeCollapsed={changeCollapsed}
      />
      <Layout>
        <Header
          className={collapsedMenu ? "collapsed" : "uncollapsed"}
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 12,
            width: '100%',
            boxShadow: "0px 1px 10px #0000003d"
          }}
        >
          <MainHeader
            refreshPage={refreshPage}
          />
        </Header>
        <Content
          key={contentKey}
          style={collapsedMenu ? {marginLeft: 80, padding: "20px 30px"} : {marginLeft: 200, padding: "20px 30px"}}
        >{ children }</Content>
        {/* <Footer>Footer</Footer> */}
      </Layout>
    </Layout>
  )
}