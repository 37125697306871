/* eslint-disable */

import React from "react";
import MainLayout from "../../layouts/MainLayout";
import ChannelProductionComp from "../../componentes/Informes/ChannelProductionComp";

export default function ChannelProduction() {
  return (
    <ChannelProductionComp />
  )
}