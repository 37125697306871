/* eslint-disable */

import React from "react";
import MainLayout from "../../layouts/MainLayout";
import MensualProductionComp from "../../componentes/Informes/MensualProductionComp";

export default function MensualProduction() {
  return (
    <MensualProductionComp />
  )
}
