import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import { useNavigate, useLocation } from 'react-router-dom';
import apiManager from "../api/apiManager";

import {
  FileTextOutlined,
  BarChartOutlined
} from '@ant-design/icons';
import { DownhillSkiing, ManageSearch, Receipt } from "@mui/icons-material";
//import {DownhillSkiingIcon} from '@mui/icons-material/DownhillSkiing';

export default function MainMenu (props) {

  const { Sider } = Layout
  const [collapsed, setCollapsed] = useState(localStorage.getItem("menuCollapsed") === 'false' ? false : true)
  const [currentPage, setCurrentPage] = useState([])
  const [isBilling, setIsBilling] = useState(false)
  const [isSubBilling, setIsSubBilling] = useState(false)
  const [isFactAdmin, setIsFactAdmin] = useState(false)
  const [isFactPremium, setIsFactPremium] = useState(false)
  let navigate = useNavigate()
  let location = useLocation()
  //testing cambio

  useEffect(() => {
    apiManager.isBillingAdmin(localStorage.getItem("token"))
    .then((res) => {
      let result = res.data.data[0]
      setIsBilling(result.user_is_billing_admin)
    })
    apiManager.isSubBillingAdmin(localStorage.getItem("token"))
    .then((res) => {
      let result = res.data.data[0]
      setIsSubBilling(result.user_is_sub_billing_admin)
    })
    apiManager.isFactAdmin(localStorage.getItem("token"))
    .then((res) => {
      let result = res.data.data[0]
      setIsFactAdmin(result.user_has_role)
    })
    apiManager.isFactPremium(localStorage.getItem("token"))
    .then((res) => {
      let result = res.data.data[0]
      setIsFactPremium(result.user_has_role)
    })
  }, [])

  useEffect(() => {
    //console.log(location)
    setCurrentPage(location.pathname)
    //console.log(localStorage.getItem("menuCollapsed"))
    //console.log(items)
  }, [location])

  function getItem(label, key, icon, children, className) {
    return {
      key,
      icon,
      children,
      label,
      className,
    };
  }

  const reports = isBilling || isSubBilling ?
    getItem('Informes', '/informes', <BarChartOutlined />, [
      isBilling ? getItem('Producción mensual', '/produc-mensual') : "",
      isBilling ? getItem('Producción por canal de venta', '/produc-canal') : "",
      isSubBilling ? getItem('Cierres de caja', '/cierres-caja') : "",
      isBilling ? getItem('Informes sobre actividades', '/informe-actividades') : "",
    ])
  : ""

  const invoices = isFactAdmin || isFactPremium ?
    getItem('Facturas', '/facturas', <Receipt />, [
      isFactAdmin ? getItem('Listado albaranes', '/listado-albaranes') : "",
      isFactAdmin ? getItem('Listado facturas', '/listado-facturas') : "",
      isFactAdmin ? getItem('Libro de facturas', '/libro-facturas') : "",
      isFactAdmin ? getItem('Pagos de facturas', '/invoices-payment') : "",
      isFactAdmin ? getItem('Reportings', '/reportings') : "",
    ])
  : ""
  
  const items = [
    getItem('Reservas', '/reservas', <FileTextOutlined />),
    getItem('Buscador de reservas', '/reservas-buscador', <ManageSearch />),
    getItem('Actividades', '/actividades', <DownhillSkiing />),
    reports,
    invoices,
    /* getItem('Informes', '/informes', <BarChartOutlined />, [
      getItem('Producción mensual', '/produc-mensual'),
      getItem('Producción por canal de venta', '/produc-canal'),
      getItem('Cierres de caja', '/cierres-caja'),
      getItem('Informes sobre actividades', '/informe-actividades')
    ], 'reports-item'), */
  ]

  const updateCollapsed = (value) => {
    localStorage.setItem("menuCollapsed", !collapsed)
    setCollapsed(!collapsed)
    props.changeCollapsed(!collapsed)
  }

  const handleClick = (item, key, keyPath) => {
    navigate(item.key)
  }

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => updateCollapsed(value)}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        zIndex: 10,
        paddingTop: 100,
        boxShadow: "1px 0px 10px #00000026"
      }}
    >
      <Menu theme="dark" selectedKeys={currentPage} mode="inline" items={items} onClick={(item, key, keyPath) => handleClick(item, key, keyPath)} />
    </Sider>
  )

}