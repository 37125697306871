import React, { useState, useEffect, useMemo } from "react";
import apiManager from "../apiManager";
import { useNavigate } from 'react-router-dom';

const UserContext = React.createContext()

export function UserProvider(props) {

  let navigate = useNavigate()

  const [userData, setUserData] = useState(null)
  const [isB2B, setIsB2B] = useState(null)

  async function getUserData() {
    const result = await apiManager.userInformation()
    if (result.data.data[0].is_b2b) {
      if (result.data.data[0].def_color !== undefined) {
        setColor(result.data.data[0].def_color)
      } else {
        setColor(process.env.REACT_APP_BASE_COLOR)
      }
    } else {
      setColor(process.env.REACT_APP_BASE_COLOR)
    }
    setUserData(result.data.data[0])
  }

  async function isB2BUser() {
    const result = await apiManager.isB2B()
    setIsB2B(result.data.data[0].user_is_b2_b)
  }

  function toHSL(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    var r = parseInt(result[1], 16);
    var g = parseInt(result[2], 16);
    var b = parseInt(result[3], 16);
    r /= 255;
    g /= 255;
    b /= 255;
    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, s, l = (max + min) / 2;

    if(max == min){
        h = s = 0; // achromatic
    } else {
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch(max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    s = s*100;
    s = Math.round(s * 10) / 10;
    l = l*100;
    l = Math.round(l * 10) / 10;
    h = Math.round(360*h);

    var colorHSL = [h, s, l]
    return colorHSL
  }

  const setColor = (newColor) => {
    let colorHSL = toHSL(newColor)
    document.documentElement.style.setProperty('--primary-color', newColor)
    document.documentElement.style.setProperty('--primary-color-h', colorHSL[0])
    document.documentElement.style.setProperty('--primary-color-s', `${colorHSL[1]}%`)
    document.documentElement.style.setProperty('--primary-color-l', `${colorHSL[2]}%`)
    document.documentElement.style.setProperty('--primary-color-dark', `hsl(${colorHSL[0]}, ${colorHSL[1]}%, calc(${colorHSL[2]}% + 8%))`)
    document.documentElement.style.setProperty('--primary-color-opacity-10', `hsla(${colorHSL[0]}, ${colorHSL[1]}%, ${colorHSL[2]}%, 0.10)`)
    document.documentElement.style.setProperty('--primary-color-opacity-15', `hsla(${colorHSL[0]}, ${colorHSL[1]}%, ${colorHSL[2]}%, 0.15)`)
    document.documentElement.style.setProperty('--primary-color-opacity-30', `hsla(${colorHSL[0]}, ${colorHSL[1]}%, ${colorHSL[2]}%, 0.30)`)
    document.documentElement.style.setProperty('--primary-color-opacity-35', `hsla(${colorHSL[0]}, ${colorHSL[1]}%, ${colorHSL[2]}%, 0.35)`)
  }

  function logoutUser() {
    localStorage.removeItem("token")
    navigate("/")
    setColor(process.env.REACT_APP_BASE_COLOR)
  }

  const value = useMemo(() => {
    return ({
      userData,
      getUserData,
      logoutUser,
      isB2BUser
    })
  }, [userData])

  return <UserContext.Provider value={value} {...props} />
}

export function useUser() {
  const context = React.useContext(UserContext)
  if (!context) {
    throw new Error('Fallo user')
  }
  return context
}