import React from "react";
import { CircleSpinnerOverlay } from "react-spinner-overlay";
import { useUser } from "../../api/context/userContext";

export default function Loader (props) {

  const { userData } = useUser()
  
  return (
    userData !== null ?
      <CircleSpinnerOverlay
        color={
          props.color !== undefined ?
            props.color
          :
            userData.def_color !== undefined ?
              userData.def_color
            :
              "#f56457"
        }
        outerBorderWidth={props.outerBorderWidth !== undefined ? props.outerBorderWidth : "6"}
        outerBorderOpacity={props.outerBorderOpacity !== undefined ? props.outerBorderOpacity : "0.3"}
        loading={props.controller}
        overlayColor={
          props.overlayColor !== undefined ?
            props.overlayColor
          :
            localStorage.getItem("viewMode") !== null && localStorage.getItem("viewMode") === "false" ?
              'rgb(0 0 0 / 60%)'
            :
              'rgb(255 255 255 / 80%)'}
      />
    : <></>
  )
}