import React, {  useState } from "react"
import logo from "../Images/rocroi-logo.png"
import "@fontsource/barlow/400.css";
import "@fontsource/barlow/600.css";
import "@fontsource/barlow/700.css";
import "./bookingtoprint.css";
import { Row, Col } from "antd";
import { useUser } from "../../api/context/userContext";

export default function BookingToPrint(props) {

  console.log(props)
  console.log(props.data.aditional_services.length)

  const { userData } = useUser()

  function importAll(r) {
    let images = {}
    r.keys().map((item, index) => { images[item.replace('./', '').split('.').shift()] = r(item); })
    return images
  }

  const images = importAll(require.context('../Images', false, /\.(png|jpe?g|svg)$/))

  const bonoInfo = props.data.center_info.bono_info
  const salesChannel = props.data.sales_channel_info

  const footer = () => {
    let footer = bonoInfo.footer !== undefined ? bonoInfo.footer : ""
    footer = footer.replace(/\\n/g,"").replace(/\\r/g,"").replace(/\\t/g,"")
    return (
      <>
        <div className="bono-footer" dangerouslySetInnerHTML={{__html: footer}}></div>
      </>
    )
  }

  return (
    <div id="doc" className="toPrintBooking" style={{padding: '30px', columns: 1}}>
      <div style={{display: "flex", justifyContent: "space-between", gap: 100, marginBottom: 30}}>
        <div>
          {
            userData.is_b2b ?
              <>
                <img src={userData.bono_avatar} alt="logo" style={{maxHeight: 55, marginLeft: 'auto', marginBottom: 15}} />
                <p><b>{ salesChannel.social_reason }</b> - { salesChannel.address }, {salesChannel.cp}, {salesChannel.city} - {salesChannel.country}</p>
              </>
            : <></>
          }
        </div>
        <div
          className="headerDoc"
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'right',
            maxWidth: 450,
            marginLeft: 'auto'
          }}
        >
          <img src={images.logoBono} alt="logo" style={{maxHeight: 55, marginLeft: 'auto', marginBottom: 15}} />
          {
            bonoInfo.center_names !== undefined ?
            bonoInfo.center_names.map((info, index) => {
                return (
                  <>
                    <p><b>{ info }</b> - { bonoInfo.address_info[index] }</p>
                  </>
                )
              })
            : ""
          }
          <p>Teléfono: { bonoInfo.phone !== undefined ? bonoInfo.phone : "" } - { bonoInfo.mail !== undefined ? bonoInfo.mail : "" }</p>
        </div>
      </div>
      <div className="bodyDoc">
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}}>
          <div>
            <h4 style={{fontWeight: 'bold'}}>Booking: <span style={{fontSize: 18, fontWeight: 600}}>
              { props.data.identifier }
            </span></h4>
            <div className="bookingInfo" style={{margin: '30px 15px'}}>
              <p><b>Fecha de reserva</b>: { props.data.created_date }</p>
              {/* <p><b>Canal</b>: { props.data.sales_channel_info.title }</p> */}
              <p><b>Nombre</b>: { props.data.client_info.name }</p>
              <p><b>Apellidos</b>: { props.data.client_info.surname }</p>
              <p><b>Fecha entrada</b>: { props.data.start_date } - Fecha salida: { props.data.end_date }</p>
              <p><b>Canal de venta</b>: { salesChannel.title }</p>
            </div>
          </div>
          <div>
            <div className="priceBooking" style={{
              width: 'max-content',
              border: '1px solid #000',
              padding: '10px 25px',
              breakBefore: 'auto'
            }}>
              {
                !userData.is_b2b ?
                  <>
                    <h4 style={{display: 'flex', justifyContent: 'space-between', gap: 10}}>
                      Total reserva: <b style={{fontSize: '1.5rem'}}>{ Number(props.data.final_price).toFixed(2).replace(/[.,]00$/, "") }€</b>
                    </h4>
                    <h4 style={{display: 'flex', justifyContent: 'space-between', gap: 10}}>
                      Total pagado: <b style={{fontSize: '1.5rem'}}>{ Number(props.data.payment_done).toFixed(2).replace(/[.,]00$/, "") }€</b>
                    </h4>
                    <h4 style={{display: 'flex', justifyContent: 'space-between', gap: 10}}>
                      Pendiente pago: <b style={{fontSize: '1.5rem'}}>{ Number(props.data.pending_price).toFixed(2).replace(/[.,]00$/, "") }€</b>
                    </h4>
                  </>
                : <h2 style={{display: 'flex', justifyContent: 'space-between', gap: 10}}>
                    Total reserva: <b style={{fontSize: '2rem'}}>{ Number(props.data.pvp_price).toFixed(2).replace(/[.,]00$/, "") }€</b>
                  </h2>
              }
            </div>
          </div>
        </div>
        <h4 style={{fontWeight: 'bold'}}>Prestación de servicio</h4>
        <div className="serviceSection" style={{margin: '10px 15px 0'}}>
          {
            props.data.booking_activities.map((activity, index, array) => {
              return (
                <div className="serviceInfo" key={`service-${activity.id}`} style={{breakInside: 'avoid', padding: '20px 0 0'}}>
                  <p>Fecha: { activity.start_date } - { activity.turns_info[0].schedule }h - Personas: { activity.num_pax }</p>
                  <h5 style={{marginTop: '0.5rem'}}>
                    <b style={{fontSize: 'initial'}}>{ activity.category_activity_name_bono }</b>
                  </h5>
                  <div className="actInfoOccupation">
                    {/* <p><b>Tarifas</b>:</p> */}
                    {
                      activity.booking_activity_occupations.map((occupation, index2) => {
                        return (
                          <>
                            {/* <p key={`occupation-${occupation.id}`}>
                              { occupation.title } x { occupation.occupation } | Precio tarifa: { occupation.price }€ | Precio total: { occupation.price * occupation.occupation }€
                            </p> */}
                            <p><b>Tarifa { index2 + 1 }</b>: {occupation.title.split(" - ").pop()} x { occupation.occupation }</p>
                          </>
                        )
                      })
                    }
                  </div>
                  {
                    index + 1 === array.length ?
                      <></>
                    : <span style={{display: 'inline-block', height: 1, background: '#000', width: 300, margin: '20px 0 0'}}></span>
                  }
                </div>
              )
            })
          }
        </div>
        {
          props.data.aditional_services.length > 0 ?
            <>
              <h4 style={{fontWeight: 'bold', marginTop: 30}}>Servicios adicionales</h4>
              <div className="aditionalServiceSection" style={{margin: '10px 15px 0'}}>
                {
                  props.data.aditional_services.map((service, index, array) => {
                    return (
                      <div className="aditionalServiceInfo" key={`aditional-service-${service.id}`} style={{breakInside: 'avoid', padding: '10px 0 0'}}>
                        <p>{ service.description }</p>
                      </div>
                    )
                  })
                }
              </div>
            </>
          : <></>
        }
        {/* <div className="priceBooking" style={{
          width: 'max-content',
          margin: '60px auto 0',
          border: '1px solid #000',
          padding: '10px 25px',
          breakBefore: 'auto'
        }}>
          <h3><b>Total reserva: { props.data.final_price }€</b></h3>
          <h3><b>Total pagado: { props.data.payment_done }€</b></h3>
          <h3><b>Pendiente pago: { props.data.pending_price }€</b></h3>
        </div> */}
      </div>
      <div className="footerDoc" style={{display: 'block', breakInside: 'avoid'}}>
        <span style={{display: 'inline-block', height: 1, margin: '50px 0', background: 'gainsboro', width: '100%'}}></span>
        { footer() }
      </div>
    </div>
  )
}