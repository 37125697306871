import React from "react";
import { useEffect, useState } from "react";
import { Table } from "antd";

import apiManager from "../../api/apiManager";

export default function TableClosedCash(props) {

  useEffect(() => {
    console.log(props)
  }, [])

  const summaryTable = (pageData) => {
    let totalPrice = 0

    pageData.forEach(({ price }) => {
      totalPrice += Number(price)
    })

    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell><b>TOTAL</b></Table.Summary.Cell>
          <Table.Summary.Cell align="center"></Table.Summary.Cell>
          <Table.Summary.Cell><b><span className={Number(totalPrice) < 0 ? "red" : ""}>{ Number(totalPrice).toFixed(2).replace(/[.,]00$/, "") + "€" }</span></b></Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    )
  }

  const columns = [
    {
      title: 'Fecha',
      dataIndex: 'date',
    },
    {
      title: 'Reserva',
      dataIndex: 'booking',
    },
    {
      title: 'Importe',
      dataIndex: 'price',
      render: (text) => <span className={Number(text) < 0 ? "red" : ""}>{ Number(text).toFixed(2).replace(/[.,]00$/, "") + "€" }</span>
    }
  ]

  return (
    <>
      <Table
        className="closedCashTable"
        bordered
        columns={columns}
        dataSource={props.data}
        pagination={false}
        summary={pageData => summaryTable(pageData)}
      />
    </>
  )

}