import React, { useState, useEffect } from "react";
import ActivityForm from "../Actividades/ActivityForm";
import { Button, Row, Col, Tag } from "antd";
import { FormOutlined, DeleteOutlined } from '@ant-design/icons';
import { Person } from "@mui/icons-material";

import { useUser } from "../../api/context/userContext";

//confirm dialog
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

import "./bookingcreatetab.css";

export default function BookingCreateTab(props) {

  const { userData } = useUser()

  const [compKey, setCompKey] = useState(0)
  const [activitiesList, setActivitiesList] = useState([])
  const [activityVisible, setActivityVisible] = useState(true)
  const [editActivity, setEditActivity] = useState(null)
  const [cancelBtn, setCancelBtn] = useState(false)

  //console.log(props)

  /* useEffect(() => {
    console.log(activitiesList)
    props.saveGlobalActivities(activitiesList, props.dateStep)
  }, [activitiesList]) */

  const activityFormComp = () => {
    if (activitiesList.length > 0) {
      setCancelBtn(true)
    }
    setCompKey(prev => prev + 1)
    setActivityVisible(true)
    props.toggleContinueBtn(false)
  }

  const saveActivity = (value) => {
    console.log(value)
    if (value.indexActivity === undefined) {
      setActivitiesList(current => [...current, value])
      let variable = activitiesList
      variable.push(value)
      props.saveGlobalActivities(variable, props.dateStep)
    } else {
      let items = activitiesList
      let item = items[value.indexActivity]
      item = value
      items[value.indexActivity] = item
      setActivitiesList(items);
      props.saveGlobalActivities(items, props.dateStep)
      setEditActivity(null)
    }
    setActivityVisible(false)
    //editActivityFunc(value)
    /* setEditActivity(
      <ActivityForm
        key={compKey}
        selectedDay={props.selectedDay}
        selectedChannel={props.selectedChannel}
        dateStep={props.dateStep}
        tabKey={props.tabKey}
        alterLabel={props.alterLabel}
        saveActivity={saveActivity}
        data={value}
      />
    ) */
  }

  const cancelChangesActivity = () => {
    setEditActivity(null)
    setActivityVisible(false)
    props.toggleContinueBtn(true)
  }

  const editActivityFunc = (value, index) => {
    props.toggleContinueBtn(false)
    setEditActivity(
      <ActivityForm
        key={compKey}
        selectedDay={props.selectedDay}
        selectedChannel={props.selectedChannel}
        dateStep={props.dateStep}
        tabKey={props.tabKey}
        alterLabel={props.alterLabel}
        saveActivity={saveActivity}
        data={value}
        indexActivity={index}
        cancelChangesActivity={cancelChangesActivity}
        userData={userData}
      />
    )
  }

  const deleteFinishedActivity = (index) => {
    var newListado = [...activitiesList];
    newListado.splice(index, 1);
    setActivitiesList(newListado);
    if (activitiesList.length === 1) {
      setActivityVisible(true)
      setCancelBtn(false)
      props.cleanGlobalActivities(props.dateStep)
    } else {
      props.saveGlobalActivities(newListado, props.dateStep)
    }
  }

  const confirmRemoveActivity = (index) => {
    confirmAlert({
      title: '¿Quieres eliminar esta actividad?',
      message: 'No se podrá recuperar una vez eliminada',
      buttons: [
        {
          label: 'Confirmar',
          onClick: () => deleteFinishedActivity(index),
          className: 'confirm-btn',
        },
        {
          label: 'Cancelar',
          className: 'cancel-btn',
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-confirm-suprimir"
    })
  }

  return (
    <>
      {
        activitiesList.length > 0 ?
          activitiesList.map((activity, index) =>
            <Row className="finishActivity" justify="space-between" key={`finishActItem-${index}`}>
              <Col span={11} style={{paddingRight: 10}}>
                <p><b>Familia de actividad</b>: {
                  activity.familiesArray.find(o => o.id === activity.formValues.activityFamily).name.split(" - ").pop()
                }</p>
                <p><b>Actividad</b>: {
                  activity.activitiesArray.find(o => o.id === activity.formValues.activity).name.split(' - ').splice(1).join(' - ')
                }</p>
                <p><b>Personas</b>: {activity.persons}</p>
                <p><b>Turno</b>: {activity.optionsTruns.find(o => o.value === activity.formValues.turns).label}</p>
                {
                  !userData.is_b2b ?
                    <p className="resourcesActivity"><b>Recursos:</b><br />
                      {
                        activity.formValues.resources !== undefined ?
                          activity.formValues.resources.map((resource, index) =>
                            resource.value === undefined ?
                              <span key={`resourceItem-${index}`}>{
                                activity.optionsResources.find(o => o.value === resource).label.split(':').shift()
                              }</span>
                              :
                              <span key={`resourceItem-${index}`}>{
                                activity.optionsResources.find(o => o.value === resource.value).label.split(':').shift()
                              }
                                <b style={{
                                  display: "inline-flex",
                                  padding: "2px 5px",
                                  marginLeft: 8,
                                  borderRadius: 5,
                                  background: "#8eb6c2",
                                  color: "#FFF"
                                }}>{resource.inputValue}<Person style={{fill: "#FFF"}} /></b>
                              </span>
                          )
                        : <Tag color="#53b454" className="simpleTag">Actividad sin recursos</Tag>
                      }
                    </p>
                  : <p><b>Recursos</b>: { activity.formValues.resources !== undefined && activity.formValues.resources.length > 0 ? activity.formValues.resources.length : 'Sin recursos'}</p>
                }
                
              </Col>
              <Col span={12} className="ratesActivity" style={{paddingInline: 10}}>
                <p style={{width: "100%"}}><b>Tarifas</b>:</p>
                {
                  activity.finishRates.map((rate, index) =>
                    <div key={`rateItem-${index}`}>
                      <div>
                        <p><b>Tarifa</b>: {rate.resumeRate.name.split(" - ").pop()}</p>
                        <p><b>Precio tarifa</b>: {
                          userData.is_b2b ? rate.resumeRate.pvp_price + "€"
                          :
                            rate.modifyRate.value === 0 ? `${rate.resumeRate.price}€` : (
                              <><del style={{color: 'red', fontSize: '14px'}}>{rate.resumeRate.price}€</del> {
                                rate.modifyRate.symbol === 'sum' ? `${rate.modifyRate.value}€` :
                                  rate.modifyRate.operator === 'add' ?
                                    `${rate.resumeRate.price + ((rate.modifyRate.value / 100) * rate.resumeRate.price)}€`
                                    : `${rate.resumeRate.price - ((rate.modifyRate.value / 100) * rate.resumeRate.price)}€`
                              }</>
                        )}</p>
                        <p><b>Unidades</b>: {rate.units}</p>
                        <p><b>Precio total</b>: {!userData.is_b2b ? rate.ratePrice : rate.ratePricePvP}€</p>
                      </div>
                    </div>
                  )
                }
              </Col>
              <Col span={1} className="actionBtns">
                <Button
                  className="iconCircle editBtn"
                  title="Editar actividad"
                  disabled={editActivity === null && !activityVisible ? false : true}
                  onClick={() => editActivityFunc(activity, index)}
                ><FormOutlined className="white" /></Button>
                <Button
                  className="iconCircle deleteBtn"
                  title="Eliminar actividad"
                  disabled={editActivity === null && !activityVisible ? false : true}
                  onClick={() => confirmRemoveActivity(index)}
                ><DeleteOutlined className="white" /></Button>
              </Col>
            </Row>
          )
        : ""
      }
      {
        activityVisible ? 
          <ActivityForm
            key={compKey}
            selectedDay={props.selectedDay}
            selectedChannel={props.selectedChannel}
            dateStep={props.dateStep}
            tabKey={props.tabKey}
            alterLabel={props.alterLabel}
            saveActivity={saveActivity}
            cancelBtnState={cancelBtn}
            cancelChangesActivity={cancelChangesActivity}
            userData={userData}
          />
        : ""
      }
      {
        !activityVisible && editActivity === null && !userData.is_b2b ?
        <Row>
          <Col span={24} style={{textAlign: "center"}}>
            <Button
              onClick={() => activityFormComp()}
              className="main-btn"
            >Añadir actividad</Button>
          </Col>
        </Row>
        : ""
      }
      {
        editActivity !== null ?
          editActivity
        : ""
      }
    </>
  )
}