import React from "react";
import { useEffect, useState } from "react";
import { Table } from "antd";

import apiManager from "../../api/apiManager";
import "./tablemensualproduction.css";

export default function TableMensualProduction(props) {

  console.log(props)
  let channelsList = []

  useEffect(() => {
    console.log(props)
    if (props.data.months.length > 0) {
      channelsList = []
      props.data.salesChannelTypeInfo.map((channel) => {
        channelsList.push({
          text: channel.salesChannelTypeInfo,
          value: channel.salesChannelTypeInfo
        })
      })
      setColumnsTable(props.data.months, channelsList)
    }
  }, [])

  const [tableUpdate, setTableUpdate] = useState(0)
  const [columns, setColumns] = useState([])
  const [totalValues, setTotalValues] = useState([])

  const setColumnsTable = (data) => {
    let numAct = data.length
    let resourcesList = data.map((resource) => {
      return {
        title: resource.title.split('-').at(-1),
        children: [{
            title: 'Pax',
            dataIndex: resource.dataIndexPax,
            width: `${100 / (numAct * 2)}%`,
            render: (text) => <span>{ text !== undefined ? text : "-" }</span>,
            align: 'center'
          },
          {
            title: 'Importe',
            dataIndex: resource.dataIndexPrice,
            width: `${100 / (numAct * 2)}%`,
            render: (text) => <span className={Number(text) < 0 ? "red" : ""}>{ text !== undefined ? text + "€" : "-" }</span>,
            align: 'center'
          }
        ]
      }
    })
    resourcesList.unshift({
      title: 'Canal',
      dataIndex: "salesChannelTypeInfo",
      width: 200,
      //sorter: (a, b) => a.salesChannelTypeInfo.localeCompare(b.salesChannelTypeInfo),
      align: 'center',
      filters: channelsList,
      onFilter: (value, record) => record.salesChannelTypeInfo.indexOf(value) === 0,
    })
    setColumns(resourcesList)
    setTableUpdate(tableUpdate + 1)
  }

  const summaryTable = (pageData) => {
    console.log(pageData)

    let values = []

    Object.entries(pageData[0]).forEach(entry => {
      if (entry[0] !== "salesChannelTypeInfo" ) {
        values.push({
          month: entry[0],
          value: 0
        })
        //console.log(entry)
      }
    })

    pageData.forEach((entry, keyEntry) => {
      Object.entries(entry).forEach(item => {
        if (item[0] !== "salesChannelTypeInfo" ) {
          //console.log(item)
          let indexValue = values.findIndex(x => x.month === item[0])
          values[indexValue].value = values[indexValue].value + Number(item[1])
          //console.log(indexValue)
        }
      })
    })

    /* console.log(values)
    setTotalValues(values)
    values.forEach((item, key) => {
      console.log(key % 2)
      if (key % 2 == 0) {
        setTotalValues({pax: totalValues.pax + item.value, import: totalValues.import})
      } else {
        setTotalValues({pax: totalValues.pax, import: totalValues.import + item.value})
      }
    }) */

    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell align="center"><b>TOTAL</b></Table.Summary.Cell>
          {
            values.map((element, key) => (
              <Table.Summary.Cell align="center"><b><span className={ key % 2 ? element.value < 0 ? "red" : "" : ""}>{parseFloat(element.value.toFixed(2).replace(/\.00$/,''))}{ key % 2 ? "€" : "" }</span></b></Table.Summary.Cell>
            ))
          }
        </Table.Summary.Row>
      </>
    )
    
    /* if (dataTableSimple.length > 0 && isAdmin) {
      let totalPax = 0;
      let totalPrice = 0;

      pageData.forEach(({ pax, price }) => {
        totalPax += pax;
        totalPrice += price;
      });
      
      return (
        <>
          <Table.Summary.Row>
            <Table.Summary.Cell colSpan={5}><b>TOTAL</b></Table.Summary.Cell>
            <Table.Summary.Cell align="center"><b>{totalPax}</b></Table.Summary.Cell>
            <Table.Summary.Cell><b>{ Number(totalPrice).toFixed(2).replace(/[.,]00$/, "") + "€" }</b></Table.Summary.Cell>
            <Table.Summary.Cell colSpan={3}></Table.Summary.Cell>
          </Table.Summary.Row>
        </>
      )
    } */
  }

  return (
    <>
      <Table
        className="mensualProdTable"
        bordered
        key={tableUpdate}
        columns={columns}
        dataSource={props.data.salesChannelTypeInfo}
        pagination={false}
        summary={pageData => summaryTable(pageData)}
      />
      <h2>{ totalValues.pax }</h2>
      <h2>{ totalValues.import }</h2>
    </>
  )
}